import https from "./https";
import store from "@/store";

const eventProduct = {
  getEventProducts(params) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/event-products`, {params});
  },
  getEventProduct(id) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/event-products/' + id)
  },
  deleteEventProduct(id) {
    const organization = store.state.general.organization
    return https.delete('admin/organizations/' + organization.id + '/event-products/' + id)
  },
  updateEventProduct(id, params) {
    const organization = store.state.general.organization
    return https.put('admin/organizations/' + organization.id + '/event-products/' + id, params)
  },
  importEventProduct(params) {
    const organization = store.state.general.organization
    return https.post('admin/organizations/' + organization.id + '/event-products/import', params)
  },
  readProductExcel(formData) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id + '/event-products/read-product-excel';

    return https.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  readBranchExcel(formData) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id + '/event-products/read-branch-excel';

    return https.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default eventProduct;
